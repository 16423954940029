import { Client } from "graphql-ws";
import { useEffect } from "react";
import { useInterval } from "usehooks-ts";
import { DefaultThemeProvider } from "~served/ui-comps";
import { bigMath } from "~served/utils";
import { useInitApp } from "~/hooks/useInitApp";
import { getHubGqlWsClient } from "~/libs/gql";
import { useGetCurrentVenueCache } from "~/queries/useGetCurrentVenue";
import { useGetOperationalGeolocationsCache } from "~/queries/useGetOperationalGeolocations";
import { useGetOperationalLocationsCache } from "~/queries/useGetOperationalLocations";
import { useGetOperationalNotifications } from "~/queries/useGetOperationalNotifications";
import { useGetOperationalOrders } from "~/queries/useGetOperationalOrders";
import { subscribeToSystemHealth } from "~/queries/useSubscribeToSystemHealth";
import { subscribeToVenueLocations } from "~/queries/useSubscribeToVenueLocations";
import { subscribeToVenueNotifications } from "~/queries/useSubscribeToVenueNotifications";
import { subscribeToVenueOrders } from "~/queries/useSubscribeToVenueOrders";
import { subscribeToVenueRefetchOperationalLocationsCommand } from "~/queries/useSubscribeToVenueRefetchOperationalLocationsCommand";
import { subscribeToVenueRefetchOperationalOrdersCommand } from "~/queries/useSubscribeToVenueRefetchOperationalOrdersCommand";
import { useVenueId } from "~/store";
const AppProvider = ({
  children
}) => {
  useInitApp();
  useWsSubscriptions();
  useRefetchOperationalData();
  return <DefaultThemeProvider data-sentry-element="DefaultThemeProvider" data-sentry-component="AppProvider" data-sentry-source-file="AppProvider.tsx">{children}</DefaultThemeProvider>;
};
export { AppProvider };
let client: Client | undefined;
const useWsSubscriptions = () => {
  const venueId = useVenueId();
  const {
    refetch: refetchOrders
  } = useGetOperationalOrders(false);
  const {
    refetch: refetchLocations
  } = useGetOperationalLocationsCache();
  const {
    refetch: refetchGeolocations
  } = useGetOperationalGeolocationsCache();
  const {
    refetch: refetchNotifications
  } = useGetOperationalNotifications(false);
  useEffect(() => {
    async function start() {
      if (client) {
        await client.dispose();
        client.terminate();
      }
      client = getHubGqlWsClient();

      /**
       * put all subscriptions here
       * so that when params change, the client is re-initialized with all the subscriptions
       */

      void subscribeToSystemHealth(client);
      if (venueId) {
        void refetchOrders();
        void refetchLocations();
        void refetchGeolocations();
        void refetchNotifications();
        void subscribeToVenueLocations(client);
        void subscribeToVenueNotifications(client);
        void subscribeToVenueOrders(client);
        void subscribeToVenueRefetchOperationalLocationsCommand(client);
        void subscribeToVenueRefetchOperationalOrdersCommand(client);
      }
    }
    void start();
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async function () {
        if (client) {
          await client.dispose();
          client.terminate();
        }
      })();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueId]);
};
const useRefetchOperationalData = () => {
  const {
    data: venue
  } = useGetCurrentVenueCache();
  const {
    refetch: refetchOrders
  } = useGetOperationalOrders(false);
  const {
    refetch: refetchLocations
  } = useGetOperationalLocationsCache();
  const {
    refetch: refetchGeolocations
  } = useGetOperationalGeolocationsCache();
  const {
    refetch: refetchNotifications
  } = useGetOperationalNotifications(false);
  useInterval(() => {
    void refetchOrders();
    void refetchLocations();
    void refetchGeolocations();
    void refetchNotifications();
  }, !!venue && !!venue.operational_data_refetch_interval ? bigMath.mul(venue.operational_data_refetch_interval, 60000) : null);
};