import { Client } from "graphql-ws";
import {
	DATA_EVENT_TYPE,
	ORDER_STATUS,
	SubscribeToVenueOrdersSubscription,
} from "~/types/__generated/gql/graphql";
import { Notification } from "~/types/store";
import {
	GetOperationalOrdersResults,
	getOperationalOrdersKey,
} from "../useGetOperationalOrders";
import { addNewNotification } from "../useSubscribeToVenueNotifications";

const query = /* GraphQL */ `
	subscription SubscribeToVenueOrders {
		subscribeToVenueOrders {
			type
			id
			payload {
				_id
				created_at
				updated_at
				index
				prepared_at
				processed_at
				paid_at
				cancelled_at
				prepared_by
				processed_by
				paid_by
				cancelled_by
				user
				customer
				venue
				status
				is_paid
				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				card_surcharge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation

				_staff {
					_id
					first_name
					last_name
				}
				_location {
					_id
					name
					type
					hash
				}
				_customer {
					_id
					first_name
					last_name
				}
			}
		}
	}
`;

export const subscribeToVenueOrders = async (client: Client) => {
	const subscription = client.iterate<SubscribeToVenueOrdersSubscription>({
		query,
	});
	for await (const result of subscription) {
		if (result.data) {
			const { type, payload } = result.data.subscribeToVenueOrders;

			switch (type) {
				case DATA_EVENT_TYPE.create: {
					window.$queryClient?.setQueryData<GetOperationalOrdersResults>(
						getOperationalOrdersKey(),
						// @ts-expect-error
						(oldData) => {
							if (payload && payload.status === ORDER_STATUS.placed) {
								const newNotification: Notification = {
									id: payload._id,
									message: `New order #${payload.index}`,
									type: "NEW_ORDER",
									severity: "warning",
									customProps: { status: payload.status },
								};
								addNewNotification(newNotification);
							}

							if (payload && oldData) return regroupOrders(payload, oldData);
						},
					);
					break;
				}
				case DATA_EVENT_TYPE.update: {
					window.$queryClient?.setQueryData<GetOperationalOrdersResults>(
						getOperationalOrdersKey(),
						// @ts-expect-error
						(oldData) => {
							if (payload && oldData) return regroupOrders(payload, oldData);
						},
					);
					break;
				}
			}
		}
	}
};

export const regroupOrders = (
	newOrder: GetOperationalOrdersResults[0],
	oldGroups: GetOperationalOrdersResults,
) => {
	const isExisting = oldGroups.some((o) => o._id === newOrder._id);
	if (isExisting) {
		return oldGroups.map((o) => (o._id === newOrder._id ? newOrder : o));
	}
	return oldGroups.concat(newOrder);
};
