import { closeSnackbar } from "notistack";
import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { ResolveAssistanceMutationVariables } from "~/types/__generated/gql/graphql";
import {
	getOperationalNotificationsKey,
	GetOperationalNotificationsResults,
} from "../useGetOperationalNotifications";

const query = /* GraphQL */ `
	mutation ResolveAssistance($id: ObjectID!) {
		resolveAssistance(id: $id) {
			_id
		}
	}
`;

export const resolveAssistanceMutationFn = async (
	variables: ResolveAssistanceMutationVariables,
) => {
	if (!window.$queryClient) throw new Error("Query client not init");

	const data = await hubGqlClient
		.request(graphql(query), variables)
		.then((res) => res.resolveAssistance);
	window.$queryClient?.setQueryData<GetOperationalNotificationsResults>(
		getOperationalNotificationsKey(),
		(prev) => (prev ? prev.filter(({ _id }) => _id !== data._id) : []),
	);
	closeSnackbar(data._id);

	return data;
};
