import { Client } from "graphql-ws";
import { SubscribeToVenueRefetchOperationalOrdersCommandSubscription } from "~/types/__generated/gql/graphql";
import {
	getOperationalOrdersKey,
	getOperationalOrdersQueryFn,
	GetOperationalOrdersResults,
} from "../useGetOperationalOrders";

const query = /* GraphQL */ `
	subscription SubscribeToVenueRefetchOperationalOrdersCommand {
		subscribeToVenueRefetchOperationalOrdersCommand {
			id
		}
	}
`;

export const subscribeToVenueRefetchOperationalOrdersCommand = async (
	client: Client,
) => {
	const subscription =
		client.iterate<SubscribeToVenueRefetchOperationalOrdersCommandSubscription>(
			{
				query,
			},
		);
	for await (const result of subscription) {
		if (result.data) {
			getOperationalOrdersQueryFn()
				.then((res) =>
					window.$queryClient?.setQueryData<GetOperationalOrdersResults>(
						getOperationalOrdersKey(),
						res,
					),
				)
				.catch(console.error);
		}
	}
};
