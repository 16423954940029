import { useQuery } from "@tanstack/react-query";
import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetOperationalNotificationsQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetOperationalNotifications {
		getOperationalNotifications {
			_id
			created_at
			updated_at
			content
			location
			_location {
				_id
				name
			}
		}
	}
`;

export type GetOperationalNotificationsResults =
	GetOperationalNotificationsQuery["getOperationalNotifications"];

export const getOperationalNotificationsKey = () => [
	"getOperationalNotifications",
];

export const useGetOperationalNotifications = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getOperationalNotificationsKey(),
		queryFn: () =>
			hubGqlClient
				.request(graphql(query))
				.then((res) => res.getOperationalNotifications),
		initialData: [],
	});
};
