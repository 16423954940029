import { Client } from "graphql-ws";
import {
	DATA_EVENT_TYPE,
	SubscribeToVenueLocationsSubscription,
} from "~/types/__generated/gql/graphql";
import {
	GetLocationByIdResults,
	getLocationByIdQueryKey,
} from "../useGetLocationById";
import {
	GetOperationalLocationsResults,
	getOperationalLocationsKey,
} from "../useGetOperationalLocations";

const query = /* GraphQL */ `
	subscription SubscribeToVenueLocations {
		subscribeToVenueLocations {
			type
			id
			payload {
				_id
				created_at
				updated_at
				venue
				name
				type
				payment_types
				status
				hash
				no_vat
				no_service_charge
				is_pay_later_allowed
				is_pay_now_allowed
				locations_groups_tags
				room_number
				default_order_note
				hidden_items_configs {
					items
					item_types
					categories
					is_items_hide_on_pos
					is_items_hide_on_qr
					is_categories_hide_on_pos
					is_categories_hide_on_qr
					is_item_types_hide_on_pos
					is_item_types_hide_on_qr
				}
				current_headcount
			}
		}
	}
`;

export const subscribeToVenueLocations = async (client: Client) => {
	const subscription = client.iterate<SubscribeToVenueLocationsSubscription>({
		query,
	});
	for await (const result of subscription) {
		if (result.data) {
			const { type, payload } = result.data.subscribeToVenueLocations;

			switch (type) {
				case DATA_EVENT_TYPE.create: {
					window.$queryClient?.setQueryData<GetOperationalLocationsResults>(
						getOperationalLocationsKey(),
						(oldData) => {
							if (!oldData) return [];
							if (!payload) return oldData;

							return [{ ...payload, _current_orders: [] }, ...oldData];
						},
					);
					break;
				}
				case DATA_EVENT_TYPE.update: {
					window.$queryClient?.setQueryData<GetOperationalLocationsResults>(
						getOperationalLocationsKey(),
						(oldData) => {
							if (!oldData) return [];
							if (!payload) return oldData;

							return oldData.map((o) =>
								o._id === payload._id ? { ...o, ...payload } : o,
							);
						},
					);
					break;
				}
				case DATA_EVENT_TYPE.delete: {
					window.$queryClient?.setQueryData<GetOperationalLocationsResults>(
						getOperationalLocationsKey(),
						(oldData) => {
							if (!oldData) return [];
							if (!payload) return oldData;

							return oldData.filter((o) => o._id !== payload._id);
						},
					);
					break;
				}
			}

			if (payload)
				window.$queryClient?.setQueryData<GetLocationByIdResults>(
					getLocationByIdQueryKey(payload._id),
					(prev) => (prev ? { ...prev, ...payload } : prev),
				);
		}
	}
};
