import { useMemo } from "react";

import { USER_PERMISSIONS, USER_ROLE } from "~served/configs";
import { Role } from "~served/types/db";

import { useAuthCache } from "~/queries/useAuthCache";
import { ROLE_PERMISSIONS, ROLE_TYPE } from "~/types/__generated/gql/graphql";

const useUserPermissions = () => {
	const { data: auth } = useAuthCache();

	const isSuperAdmin = auth ? auth.type === ROLE_TYPE.super_admin : false;
	const isAdmin = auth ? auth.type === ROLE_TYPE.admin : false;
	const isManager = auth ? auth.type === ROLE_TYPE.manager : false;
	const isSupervisor = auth ? auth.type === ROLE_TYPE.supervisor : false;
	const isStaff = auth ? auth.type === ROLE_TYPE.staff : false;
	const isJuniorStaff = auth ? auth.type === ROLE_TYPE.junior_staff : false;

	// Allow for any role that is logged in
	const isAuthenticated =
		isSuperAdmin ||
		isAdmin ||
		isManager ||
		isSupervisor ||
		isStaff ||
		isJuniorStaff;

	const PERMISSIONS = {
		AUTO_LOGOUT_TIME: getLogoutTime(auth?.type, auth?.permissions),
		ADJUST_RAW_MATERIAL_RECEIVED: isSuperAdmin || isAdmin,
		CANCEL_PAID_ORDER: isSuperAdmin || isAdmin,
		EDIT_BILL_PRINTED_ORDER: isSuperAdmin || isAdmin,
		EDIT_PAID_ORDER: isSuperAdmin || isAdmin || isManager,
		EDIT_UNPAID_ORDER: isAuthenticated,
		LOCATION_MENU_ACCESS: isSuperAdmin || isAdmin,
		LOCATION_DELETE: isSuperAdmin,
		LOCATION_GET_QR: isSuperAdmin,
		LOCATION_OPEN_CONSUMER_APP: isSuperAdmin,
		LOCATION_LOCATION_GROUP: isSuperAdmin,
		PRINT_LOG_ADJUSTMENT_VALUE: isSuperAdmin,
		PRINT_LOG_DISCOUNT: isSuperAdmin || isAdmin || isManager,
		PRINT_LOG_OFFER: isSuperAdmin || isAdmin || isManager,
		PRINT_LOG_VAT: isSuperAdmin || isAdmin || isManager,
		PRINT_LOG_PAYMENT_TYPE:
			isSuperAdmin || isAdmin || isManager || isSupervisor || isStaff,
		PRINT_LOG_PAYMENT_AMOUNT: isSuperAdmin || isAdmin || isManager,
		PRINT_LOG_RECEIPT_ID:
			isSuperAdmin || isAdmin || isManager || isSupervisor || isStaff,
		PRINT_LOG_SC: isSuperAdmin || isAdmin || isManager,
		SETTINGS_PAGE_ACCESS: isSuperAdmin || isAdmin,
		SETTINGS_CHECKLIST: isSuperAdmin,
		SETTINGS_TICKET: isSuperAdmin,
		SETTINGS_ORDER_COMMENT: isSuperAdmin,
		SETTINGS_VAT: isSuperAdmin || isAdmin,
		SETTINGS_SC: isSuperAdmin || isAdmin,
		SETTINGS_ITEM_TYPE: isSuperAdmin || isAdmin,
		SETTINGS_PAYMENT_TYPE: isSuperAdmin || isAdmin,
		SETTINGS_SHIFTS: isSuperAdmin || isAdmin,
		SETTINGS_LOCATION_GROUP: isSuperAdmin,
		SETTINGS_PRINTER: isSuperAdmin,
		TILL_PRICE_ADJUSTMENT: isSuperAdmin || isAdmin || isManager,
		TILL_EXCHANGE_RATE: isSuperAdmin,
		FLOORPLAN_UPDATE: isSuperAdmin || isAdmin,
		SETTINGS_ADD_ONS: isSuperAdmin,
		SETTING_UNLIMIT_USER_CREATE: isSuperAdmin,
		SETTINGS_CREATE_USER: isSuperAdmin || isAdmin || isManager,
		SETTING_USER_RESET_PASSWORD: isSuperAdmin || isAdmin,
		SETTING_USER_DELETE: isSuperAdmin || isAdmin,
	};

	return PERMISSIONS;
};

const useUserRulePermissions = () => {
	const { data: auth } = useAuthCache();

	const permissionsDictionary: Record<keyof typeof USER_PERMISSIONS, boolean> =
		useMemo(() => {
			const dictionary = Object.keys(USER_PERMISSIONS).reduce(
				(acc, key) => {
					acc[key] = false;
					return acc;
				},
				{} as Record<keyof typeof USER_PERMISSIONS, boolean>,
			);

			if (auth) {
				auth.permissions.forEach((permission) => {
					dictionary[permission] = true;
				});
			}

			return dictionary;
		}, [auth]);

	return permissionsDictionary;
};

export { useUserPermissions, useUserRulePermissions };

const getLogoutTime = (
	type?: Role["type"],
	permissions?: ROLE_PERMISSIONS[],
) => {
	const isAdmin = type === USER_ROLE.SUPER_ADMIN || type === USER_ROLE.ADMIN;
	const isAutoLogout = permissions?.find(
		(str) => str === ROLE_PERMISSIONS.AUTO_LOGOUT,
	)
		? true
		: false;

	if (isAdmin || !isAutoLogout) return 604800000; // 1 week
	return 180000; // 3 mins
};
