import { useQuery } from "@tanstack/react-query";
import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetOperationalLocationsQuery } from "~/types/__generated/gql/graphql";
import { isOrderUnpaid } from "~served/utils/calculation";
import { bigMath } from "~served/utils/misc";

const query = /* GraphQL */ `
	query GetOperationalLocations {
		getOperationalLocations {
			_id
			created_at
			updated_at
			venue
			name
			type
			payment_types
			status
			hash
			no_vat
			no_service_charge
			is_pay_later_allowed
			locations_groups_tags
			room_number
			default_order_note
			hidden_items_configs {
				items
				item_types
				categories
				is_items_hide_on_pos
				is_items_hide_on_qr
				is_categories_hide_on_pos
				is_categories_hide_on_qr
				is_item_types_hide_on_pos
				is_item_types_hide_on_qr
			}
			current_headcount
			_current_orders {
				_id
				is_paid
				is_cancelled
				grand_total
			}
		}
	}
`;

export type GetOperationalLocationsResults =
	GetOperationalLocationsQuery["getOperationalLocations"];

export const getOperationalLocationsKey = () => ["getOperationalLocations"];

export const getOperationalLocationsQueryFn = () =>
	hubGqlClient
		.request(graphql(query))
		.then((res) => res.getOperationalLocations);

export const useGetOperationalLocations = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getOperationalLocationsKey(),
		queryFn: getOperationalLocationsQueryFn,
	});
};

export const useGetOperationalLocationsCache = () => {
	return useGetOperationalLocations(false);
};

export const getOperationalLocationsCache = async () => {
	const cached =
		window.$queryClient?.getQueryData<GetOperationalLocationsResults>(
			getOperationalLocationsKey(),
			{ exact: true },
		);
	if (cached !== undefined) return cached;

	await window.$queryClient?.refetchQueries(getOperationalLocationsKey(), {
		exact: true,
	});
	return (
		window.$queryClient?.getQueryData<GetOperationalLocationsResults>(
			getOperationalLocationsKey(),
			{ exact: true },
		) ?? []
	);
};

export const getLocationUnpaidGrandTotal = (
	location: GetOperationalLocationsResults[0],
) =>
	location._current_orders
		// @ts-ignore
		.filter(isOrderUnpaid)
		.reduce((pre, cur) => bigMath.add(pre, cur.grand_total), 0);
