import { useQuery } from "@tanstack/react-query";
import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import {
	GetOperationalOrdersQuery,
	ORDER_STATUS,
} from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetOperationalOrders {
		getOperationalOrders {
			_id
			created_at
			updated_at
			index
			prepared_at
			processed_at
			paid_at
			cancelled_at
			prepared_by
			processed_by
			paid_by
			cancelled_by
			user
			customer
			venue
			status
			is_paid
			staff
			original_orders
			cancelled_reason
			source
			device
			prep_time
			currencies_configs
			location
			current_location
			max_prepare_time
			items_count
			items_count_by_types
			prepped_count
			subtotal
			offer_amount
			discount_amount
			net_amount
			vat_amount
			service_charge_amount
			card_surcharge_amount
			adjustment_amount
			grand_total
			gross_amount
			cancelled_amount
			receipt
			is_needing_prep_time_confirmation
			note
			is_cancelled
			is_bill_printed
			is_needing_payment_confirmation
			_staff {
				_id
				first_name
				last_name
			}
			_location {
				_id
				name
				type
				hash
			}
			_customer {
				_id
				first_name
				last_name
			}
		}
	}
`;

export type GetOperationalOrdersResults =
	GetOperationalOrdersQuery["getOperationalOrders"];

export const getOperationalOrdersKey = () => ["getOperationalOrders"];

export const getOperationalOrdersQueryFn = () =>
	hubGqlClient.request(graphql(query)).then((res) => res.getOperationalOrders);

export const useGetOperationalOrders = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getOperationalOrdersKey(),
		queryFn: getOperationalOrdersQueryFn,
	});
};

export const usePlacedOrders = () => {
	const { data = [], ...rest } = useGetOperationalOrders(false);

	return {
		...rest,
		data: data.filter((order) => order.status === ORDER_STATUS.placed),
	};
};

export const usePreparingOrders = () => {
	const { data = [], ...rest } = useGetOperationalOrders(false);

	return {
		...rest,
		data: data.filter((order) => order.status === ORDER_STATUS.preparing),
	};
};

export const useProcessedOrdrs = () => {
	const { data = [], ...rest } = useGetOperationalOrders(false);

	return {
		...rest,
		data: data.filter((order) => order.status === ORDER_STATUS.processed),
	};
};

export const getOrdersCache = () => {
	return (
		window.$queryClient?.getQueryData<GetOperationalOrdersResults>(
			getOperationalOrdersKey(),
			{
				exact: true,
			},
		) ?? []
	);
};
