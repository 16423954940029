import { QueryClient } from "@tanstack/react-query";
import { PersistedClient, Persister, PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { del, get, set } from "idb-keyval";
import { useEffect } from "react";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "offlineFirst",
      cacheTime: Infinity,
      retry: false
    },
    mutations: {
      networkMode: "offlineFirst",
      cacheTime: Infinity,
      retry: false
    }
  }
});
function createIDBPersister(idbValidKey: IDBValidKey = "served:staff:query-client") {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    }
  } satisfies Persister;
}
const QueryProvider = ({
  children
}) => {
  useEffect(() => {
    window.$queryClient = queryClient;
  }, []);
  return <PersistQueryClientProvider client={queryClient} persistOptions={{
    persister: createIDBPersister()
  }} data-sentry-element="PersistQueryClientProvider" data-sentry-component="QueryProvider" data-sentry-source-file="QueryProvider.tsx">
			{children}
		</PersistQueryClientProvider>;
};
export { QueryProvider };
declare global {
  interface Window {
    /** App query client instance, available after app mounted.
     * ONLY USE THIS WHEN HOOKS IS INACCESSIBLE.
     * */
    $queryClient?: QueryClient;
  }
}