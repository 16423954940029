import { useQuery } from "@tanstack/react-query";
import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetActiveMenuQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetActiveMenu {
		getActiveMenu {
			_id
			created_at
			updated_at
			name
			description
			banner_img
		}
	}
`;

export type GetActiveMenuResults = GetActiveMenuQuery["getActiveMenu"];

export const getActiveMenuQueryKey = () => ["getActiveMenu"];

export const useGetActiveMenu = (enabled?: boolean) => {
	return useQuery({
		enabled,
		queryKey: getActiveMenuQueryKey(),
		queryFn: () =>
			hubGqlClient.request(graphql(query)).then((res) => res.getActiveMenu),
	});
};

export const useGetActiveMenuCache = () => {
	return useGetActiveMenu(false);
};
